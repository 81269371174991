import React, { Component } from 'react';
import { motion } from "framer-motion";
import HeaderComponent from '../Components/header.component';
import { FooterComponent } from '../Components/footer.component';
import ValuesComponent from '../Components/values.component';
import AccordionComponent from '../Components/accordion.component';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

export default class ProgramsPage extends Component {

  componentDidMount() {
    document.title = "Programs";
  }
  render() {
    return (
      <TransitionComponent>
        <HeaderComponent />
        <motion.section
          className='section'
          initial={{ opacity: 0, transform: `translateY(240px)` }}
          whileInView={{ opacity: 1, transform: `translateY(0px)` }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div className="column32">
            <div className="column16">
              <div className="flex64">
                <img
                  src="./Assets/Images/vextor icon x.png"
                  loading="lazy"
                  alt=""
                  className="icon-image"
                />
                <h1 className="title-large">Program Building Blocks</h1>
              </div>
              <div className="divider" />
            </div>
            <div className="image-container">
              <ImageComponent
                src="./Assets/Images/Orphans/1.png"
                loading="lazy"
                alt=""
                className="image-fluid"
              />
            </div>
            <p className="text">
              We use community-based participatory planning and action approaches
              to design our program building blocks including identifying the
              needs, pathways and solutions to the integration and transformation
              agenda of OVC.
            </p>
          </div>
        </motion.section>
        <ValuesComponent />
        <motion.section
          className='section'
          initial={{ opacity: 0, transform: `translateY(240px)` }}
          whileInView={{ opacity: 1, transform: `translateY(0px)` }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div className="column32 stretch">
            <div className="column8">
              <AccordionComponent title="Building Block 1: Compassionate (Discipleship) Outreach">
                <div className="col6">
                  <div className="column16">
                    <p className="text">
                      Our compassionate outreach include listening to the
                      needs of OVC, giving comfort and reassurance, and
                      acting to solve urgent problems while working toward
                      long-term solutions
                    </p>
                    <ul className="list">
                      <li className="list-item">Food</li>
                      <li className="list-item">Medical Assistance</li>
                      <li className="list-item">&nbsp;Education Assistance</li>
                      <li className="list-item">Transitional Home</li>
                      <li className="list-item">Psycho social support</li>
                    </ul>
                  </div>
                </div>
                <div className="col6">
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/2.jpg"
                      loading="lazy"
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                </div>
              </AccordionComponent>
              <AccordionComponent title="Building Block 2: Institutional Capacity Strengthening">
                <div className="col6">
                  <div className="column16">
                    <p className="text">
                      This involves strengthening the capacity of churches
                      and FBOs in their leadership and governance structure;
                      community outreach work and action-based planning to
                      better serve OVC.
                    </p>
                    <p className="bold-text">
                      The project component is built from consultations and
                      involvement pastoral team.
                    </p>
                  </div>
                </div>
                <div className="col6">
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/3.png"
                      loading="lazy"
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                </div>
              </AccordionComponent>
              <AccordionComponent title="Building Block 3: Reconciliation & Community Integration">
                <div className="col6">
                  <div className="column16">
                    <p className="text">
                      We define reconciliation as a transformative process
                      of restoring relationships with oneself, others,
                      creation, and the creator. It includes activities that
                      transform the behaviors of OVC and impact the
                      community's willingness to open its doors to support
                      them.
                    </p>
                    <ul className="list">
                      <li className="list-item">
                        &nbsp;Family strengthening programs
                      </li>
                      <li className="list-item">
                        Community strengthening activities to restore
                        communal fabric to&nbsp;&nbsp;OVC integration
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col6">
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/4.jpg"
                      loading="lazy"
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                </div>
              </AccordionComponent>
              <AccordionComponent title="Building Block 4: Advocacy">
                <div className="col6">
                  <div className="column16">
                    <p className="text">
                      We plan in using the envisioned successes, best
                      practices from Block 3 (Reconciliation and Community
                      Integration) to design a program component that
                      strengthen community participation as vehicle to raise
                      awareness amongst government and other stakeholders on
                      policy implications to address OVC challenges.
                    </p>
                  </div>
                </div>
                <div className="col6">
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/5.png"
                      loading="lazy"
                      alt=""
                      className="image-fluid contain"
                    />
                  </div>
                </div>
              </AccordionComponent>
            </div>
          </div>
        </motion.section>
        <FooterComponent />
      </TransitionComponent>
    )
  }
}
