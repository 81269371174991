import React from 'react';
import { FooterComponent } from '../Components/footer.component';
import HeaderComponent from '../Components/header.component';
import FeatherIcon from 'feather-icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import VideoPlayerComponent from '../Components/video.component';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

const HomePage = () => {
    const [isVisible, setIsVisible] = React.useState(false);

    const toggleVideoPlayer = (ev) => {
        ev.preventDefault();
        setIsVisible(!isVisible);
    }

    const videoOptions = {
        src: './Assets/Video/Rev 1 final.mp4',
        poster: './Assets/Video/Rev 1 final.jpg',
    };

    React.useEffect(() => {
        document.title = "Kadosh Voice Of Glory International";
    }, []);
    return (
        <TransitionComponent>
            <HeaderComponent />
            {/* Hero Section */}
            <motion.section className='flexAuto stretch hero'
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className='hero-desc'>
                    <div className='p64'>
                        <div className='column64 stretch'>
                            <div className='column16'>
                                <h2 className='sub-title'>
                                    Fostering hope &amp; restoring dignity for
                                </h2>
                                <h1 className='title-large'>
                                    Orphaned &amp;
                                    <br />
                                    Vulnerable Children
                                </h1>
                                <p className='text'>
                                    Our work is modelled from evidence-based research that has
                                    proven that holistic ministry in family and community-based
                                    orphan care models provides a nurturing environment for children
                                    to thrive and impacts their well-being.
                                </p>
                            </div>
                            <div className='flexAuto'>
                                <a href='/donate' className='button-primary'>
                                    Donate Now
                                    <FeatherIcon icon='arrow-up-right' size={22} />
                                </a>
                                <a href='#!' onClick={toggleVideoPlayer}>
                                    <img
                                        src='./Assets/Images/Video Icon.png'
                                        loading='lazy'
                                        alt=''
                                        className='image-button'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hero-image'>
                    <ImageComponent
                        src='./Assets/Images/hero-img.jpg'
                        loading='lazy'
                        alt=''
                        className='hero-img'
                    />
                </div>
            </motion.section>
            {/* Message From Executive Desk */}
            <motion.section className='dark-bg'
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className='flex64'>
                    <div className='col6'>
                        <div className='column16'>
                            <div>
                                <ImageComponent
                                    src='./Assets/Images/Team/Elizabeth Mago.png'
                                    loading='lazy'
                                    alt=''
                                    className='image-fluid'
                                />
                            </div>
                            <h2 className='sub-title'>Elizabeth Mago</h2>
                        </div>
                    </div>
                    <div className='col6'>
                        <div className='column32'>
                            <div className='column16'>
                                <h2 className='title-small'>
                                    Message from Executive Desk
                                </h2>
                                <div className='divider'/>
                            </div>
                            <p className='text'>
                                The family and community-based model of orphan care aligns with
                                international human rights standards, including the United Nations
                                Convention on the Rights of the Child, which emphasizes every
                                child's right to grow up in a family environment. Research has shown
                                that these approaches are more effective in promoting the well-being
                                and healthy development of orphaned and vulnerable children compared
                                to institutional care.
                                <br />
                                <br />
                                Churches and Faith-Based Organizations (FBOs) play a crucial role in
                                providing compassionate outreach, safe havens, and nurturing care to
                                orphaned and vulnerable children (OVC). They are instrumental in
                                facilitating the shift towards family and community-based orphan
                                care models. Guided by a moral and ethical framework of compassion,
                                care, and social justice, these institutions are passionate about
                                establishing and sustaining positive relationships and making a
                                significant impact on OVC, families, and communities. Our
                                transformational efforts focus on promoting family preservation,
                                integration, and reintegration of OVC into social and community
                                networks, thereby creating a sense of belonging and improving their
                                mental, social, and physical out comes. <br />
                                &zwj;
                                <br />
                                We recognize the gaps identified by ongoing research and aim to
                                strengthen the capacity of churches and FBOs to enhance the quality
                                and commitment of OVC support and impact. Our partnership model
                                invests in working with both communities and churches to enhance the
                                technical capacities of these institutions. This includes promoting
                                inclusivity, transparency, accountability, community mobilization
                                and engagement, networking and collaboration, and providing
                                psychosocial support.
                            </p>
                        </div>
                    </div>
                </div>
            </motion.section>
            {/* Who we target */}
            <motion.section className='section'
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className='column32 stretch'>
                    <div className='column8'>
                        <h2 className='title-small'>Who Do We Target</h2>
                        <div className='divider'/>
                    </div>
                    <p className='text'>
                        We promote church and community involvement in working with these
                        institutions of OVC care; at risk OVC into family and community support
                        systems; and children living in streets in our journey of strengthening
                        their social networks necessary to achieve our vision. Our home plus model
                        of institutionalizing OVC is a short term emergency solution with a strong
                        psycho-social support base and roadmap of transitioning into families and
                        communities.
                    </p>
                    <div className='flex64'>
                        <div className='col4'>
                            <div className='card'>
                                <div className='column'>
                                    <div className='card-image'>
                                        <ImageComponent
                                            src='./Assets/Images/Orphans/Children in Institutions.jpg'
                                            loading='lazy'
                                            alt=''
                                            className='card-img'
                                        />
                                    </div>
                                    <div className='p32'>
                                        <p className='bold-text'>
                                            Children in Institutions
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col4'>
                            <div className='card'>
                                <div className='column'>
                                    <div className='card-image'>
                                        <ImageComponent
                                            src='./Assets/Images/Orphans/At-risk OVC in families and communities.png'
                                            loading='lazy'
                                            alt=''
                                            className='card-img'
                                        />
                                    </div>
                                    <div className='p32'>
                                        <p className='bold-text'>
                                            At-risk OVC in families and communities
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col4'>
                            <div className='card'>
                                <div className='column'>
                                    <div className='card-image'>
                                        <ImageComponent
                                            src='./Assets/Images/Orphans/Children living in the street.jpg'
                                            loading='lazy'
                                            alt=''
                                            className='card-img'
                                        />
                                    </div>
                                    <div>
                                        <div className='p32'>
                                            <p className='bold-text'>
                                                Children living in the street
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.section>
            {/* Footer */}
            <FooterComponent />
            <AnimatePresence>
                {
                    isVisible && <motion.div
                        initial={{ opacity: 0, transform: `translateY(240px)` }}
                        animate={{ opacity: 1, transform: `translateY(0px)` }}
                        transition={{ duration: 1 }}
                        className='backdrop'>
                        <a href='#!' className='icon-button backdrop-close' onClick={toggleVideoPlayer}><FeatherIcon icon='x' size={22} /></a>

                        <div className='video-player'>
                            <VideoPlayerComponent options={videoOptions} />
                        </div>

                    </motion.div>
                }
            </AnimatePresence>
        </TransitionComponent>
    );
}

export default HomePage;