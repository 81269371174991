import React, { Component } from 'react';
import { motion } from "framer-motion";
import HeaderComponent from '../Components/header.component';
import { FooterComponent } from '../Components/footer.component';
import VideoPlayerComponent from '../Components/video.component';
import ValuesComponent from '../Components/values.component';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

const vjsOptions = {
  src: "./Assets/Video/Methodist final 1.mp4",
  poster: "./Assets/Video/Methodist final 1.jpg"
};
export default class CurrentPage extends Component {

  componentDidMount() {
    document.title = "Supporting Vulnerable Children and Families";
  }
  render() {
    return (
      <TransitionComponent>
        <HeaderComponent />
        <motion.section
          className="section"
          initial={{ opacity: 0, transform: `translateY(240px)` }}
          whileInView={{ opacity: 1, transform: `translateY(0px)` }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div className="column64">
            <div className="column32">
              <div className="column16">
                <h1 className="title-large">Current Work</h1>
                <p className="sub-title">Zimbabwe</p>
                <div className="divider" />
              </div>
              <div className="column16">
                <p className="bold-text">Harare</p>
                <p className="text">
                  Kadosh Voice of Glory International outreach program is
                  partnering with St. Marceline Children Village and Methodist
                  Church (Selous Branch) to provide food assistance to 600+ OVCs.
                  Through the Methodist Church in Zimbabwe, this assistance
                  reached homeless children through their kitchen soup program.
                  The objective of our compassionate outreach of food assistance
                  is tailored to address the immediate needs of children, while
                  building relationships for continuous psycho-social support.
                </p>
                <p className="bold-text">Bulawayo</p>
                <p className="text">
                  Through the Dutch Reformed Church (DRC-Z) * Nkhulumani Branch,
                  we are providing family strengthening programs to build healthy
                  relationships within families and encourage them to open their
                  doors to fostering orphans and vulnerable children. Using the
                  existing DRC-Z church social structures for community
                  mobilization,we run emotional and spiritual support groups for
                  OVC and families that regularly meet up to share experiences,
                  offer emotional support and learn from each other. The purpose
                  of the groups is to also provide continuous counselling to
                  resolve conflicts and improve family dynamics. Our parenting
                  workshops are welcome to both church members and community as
                  strengthening relationships between children and their
                  caregivers.
                </p>
              </div>
            </div>
          </div>
        </motion.section>
        <ValuesComponent />
        <motion.section
          className="section"
          initial={{ opacity: 0, transform: `translateY(240px)` }}
          whileInView={{ opacity: 1, transform: `translateY(0px)` }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <div className="column64 stretch">
            <div className="column32 stretch">
              <div className="sub-title">
                Compassionate Outreach to Children Experiencing Homelessness
              </div>
              <div className="flex16">
                <div className='col6'>
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/6.jpg"
                      loading="lazy"
                      alt=""
                      className="image-fluid"
                    />
                  </div>
                </div>
                <div className='col6'>
                  <div
                    className="image-container small"
                  >
                    <ImageComponent
                      src="./Assets/Images/Orphans/7.jpg"
                      loading="lazy"
                      alt="A group of people standing around each other"
                      className="image-fluid"
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="column32">
              <div className="flex16">
                <ImageComponent
                  src="./Assets/Images/vector icon x42.png"
                  loading="lazy"
                  alt=""
                  className="icon-image small"
                />
                <div className="column16">
                  <div className="sub-title">Next Plans</div>
                  <p className="text">
                    For greater impact, KVGI is working on a baseline study to
                    inform program design and provide insight into the theory of
                    change in meeting program goals and objectives
                  </p>
                </div>
              </div>
            </div>

            <VideoPlayerComponent options={vjsOptions} />

          </div>
        </motion.section>
        <FooterComponent />
      </TransitionComponent>
    )
  }
}
