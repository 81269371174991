import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  useRouteError,
  RouterProvider,
  NavLink,
  createHashRouter,
} from "react-router-dom";
import HomePage from './Pages/home.page';
import AboutPage from './Pages/about.page';
import PartnersPage from './Pages/partners.page';
import TeamPage from './Pages/team.page';
import CurrentPage from './Pages/current.page';
import DonatePage from './Pages/donate.page';
import ContactPage from './Pages/contact.page';
import ProgramsPage from './Pages/programs.page';
import FeatherIcon from 'feather-icons-react';
import { AnimatePresence } from 'framer-motion';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="error-page">
      <h1 className='error-title'>{error.status}</h1>
      <p className='error-text'>
        {/* Sorry, an unexpected error has occurred.  */}
        {error.statusText || error.message}
      </p>
      <i className='error-text'>
        {error.message || error.data}
        <br /><br />
        {error.error.stack || error.message}
      </i>
      <NavLink to={`/home`} className='error-button'>Go to Home <FeatherIcon icon='arrow-up-right' size={22} /></NavLink>
    </div>
  );
}

const router = createHashRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/home",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/about",
    element: <AboutPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/partners",
    element: <PartnersPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/team",
    element: <TeamPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/programs",
    element: <ProgramsPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/current-work",
    element: <CurrentPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/contact",
    element: <ContactPage />,
    errorElement: <ErrorPage />,
  }, {
    path: "/donate",
    element: <DonatePage />,
    errorElement: <ErrorPage />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AnimatePresence>
      <RouterProvider router={router} />
    </AnimatePresence>
    {/* <HomePage /> */}
  </React.StrictMode >
);
