import React from 'react';
import HeaderComponent from '../Components/header.component';
import { FooterComponent } from '../Components/footer.component';
import ValuesComponent from '../Components/values.component';
import VideoPlayerComponent from '../Components/video.component';
import { motion } from 'framer-motion';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

const PartnersPage = () => {

  const vjsOptions = {
    src: "./Assets/Video/Pastor Tsitsi draft 2.mp4",
    poster: "./Assets/Video/Pastor Tsitsi draft 2.jpg",
  };
  React.useEffect(() => {
    document.title = "Partners";

  }, []);

  return (
    <TransitionComponent>
      <HeaderComponent />
      <motion.section className="section"
        initial={{ opacity: 0, transform: `translateY(240px)` }}
        whileInView={{ opacity: 1, transform: `translateY(0px)` }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}>
        <div className="container">
          <div className="column32">
            <div className="column16">
              <h1 className='title-large'>Whom Do We Work With</h1>
              <div className='divider'></div>
            </div>
            <div className="column16">
              <div >
                <p className="text">We work with churches and faith-based organizations whose underlying
                  doctrine and practices do uphold the human rights of OVC and do no harm to them according to:</p>
                <ol className="orderlist">
                  <li><p className='text'><b>UN Convention on the Rights of the Child.</b></p> </li>
                  <li><p className='text'><b>African Charter on the Rights and welfare of the Child.</b></p> </li>
                </ol>
                <p className="text">We engage with volunteers, families and communities to bring about
                  transformation, which enables them to enjoy the fulfilment and fullness of serving humanity (vulnerable). <br /><br />The program therefore works to strengthen the capacity of churches and
                  FBOs as allies in partnering with communities in their locally defined goals, agreed plans, joint
                  ownership and collective efforts in responding to OVC needs.</p>
              </div>
            </div>
          </div>
        </div>
      </motion.section>
      <ValuesComponent />
      <motion.section className="section"
        initial={{ opacity: 0, transform: `translateY(240px)` }}
        whileInView={{ opacity: 1, transform: `translateY(0px)` }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}>
        <div className="column32">
          <div className="column16">
            <h1 className='title-large'>Why Are churches At The center Of Our Partnership Model?</h1>
            <div className='divider'></div>
          </div>
          <div className="column16">
            <h3 className='sub-title'>In Patnering With Churches</h3>

            <p className='text'>The Emotional and Spiritual support of OVC is at the heart of the work of churches in transforming individuals, families and communities to live positively<br /><br />Within their ethical and moral framework, Churches tirelessly offer continuous psycho- social support to individuals and families who need it.<br /><br />Churches can therefore provide a safe space for vulnerable children from diverse backgrounds to gather, engage and seek solace. They help pave the way for the success of our integration and reintegration process  by meeting the ongoing emotional, social and spiritual needs of vulnerable children and families as they face life challenges.</p>

          </div>

          <ImageComponent alt='' src="https://cdn.prod.website-files.com/66844b71b58456befd3cb336/6689ef5673b3f88ba662e287_Infographic%20V-03-02.jpg" className='image-fluid' />

          <h3 className='sub-title'>In Directly Working With Communities</h3>
          <p className='text'>
            Our community toolbox employs and emphasize a subset of spiritual assets necessary to create an enabling and supportive environment for OVC transitioning in healthy families and communities. These are qualities that transcend religion, tradition, sect or denomination and are universal in practice and application.
            <br /><br />
            We define 'spirituality' as those assets that empower us to do what is right and beneficial for ourselves and others, as reflected in our words and actions. These includes values of compassion, mercy, appreciation and gratitude, patience, kindness, being charitable to others, humility, forgiveness and reconciliation.</p>
          <VideoPlayerComponent options={vjsOptions} />
        </div>


      </motion.section>
      <FooterComponent />
    </TransitionComponent>
  )

}

export default PartnersPage;