import React from 'react';
import { motion } from 'framer-motion';
import FeatherIcon from 'feather-icons-react';
import { useMediaQuery } from '@mui/material';
import ImageComponent from './image.component';


const ValuesComponent = () => {
    const [onCarousel, setOnCarousel] = React.useState(true);
    const isMobile = useMediaQuery('(max-width:1024px)');
    const variants = {
        scrollLeft: {
            transform: "translate3d(0px, 0px, 0px)",
            transformStyle: "preserve-3d",
        },

        scrollRight: {
            transform: isMobile ? "translate3d(-1640px, 0px, 0px)" : "translate3d(calc(-840px), 0px, 0px)",
            transformStyle: "preserve-3d",
        }
    };


    const toggleCarousel = (ev) => {
        ev.preventDefault();
        setOnCarousel(!onCarousel);
    }

    return (
        <motion.section className='dark-bg'
            initial={{ opacity: 0, transform: `translateY(240px)` }
            }
            whileInView={{ opacity: 1, transform: `translateY(0px)` }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >
            <div className='column32 stretch'>
                <div className="rowAuto">
                    <div className="column16">
                        <h2 className="sub-title white">What We Stand For</h2>
                        <h1 className="title-large white">Our Values</h1>
                        <div className="divider white ">
                        </div>
                    </div>
                    <div className="row">
                        {/* Back */}
                        <button className='icon-button borderless' onClick={toggleCarousel}>
                            <FeatherIcon icon="arrow-left" size={22} />
                        </button>
                        {/* Forwards */}
                        <button className='icon-button borderless' onClick={toggleCarousel}>
                            <FeatherIcon icon="arrow-right" size={22} />
                        </button>
                    </div>
                </div>
                <div className="carousel visible">
                    <div className="carouseldivider" />
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Healing & Reconciliation.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title center">Healing &amp; Reconciliation</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Social Inclusion.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title center">Social Inclusion</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Community Participation.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title center">Community Participation</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Compassion & Faith.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title center">Compassion &amp; Faith</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Cultural Sensitivity.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title">Cultural Sensitivity</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Collaboration.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title">Collaboration</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Communication.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title">Communication</div>
                    </motion.div>
                    <motion.div animate={onCarousel ? "scrollLeft" : "scrollRight"} variants={variants} transition={{ duration: 1 }} className="column16 center">
                        <div className="carousel-container">
                            <ImageComponent src="./Assets/Images/Values/Hope & Dignity.png" loading="lazy" alt="" className="carousel-image" />
                        </div>
                        <div className="sub-title">Hope &amp; Dignity</div>
                    </motion.div>
                </div>
            </div>
        </motion.section >
    );

}

export default ValuesComponent;