import React from 'react';
import { AnimatePresence, motion } from "framer-motion";
import HeaderComponent from '../Components/header.component';
import { FooterComponent } from '../Components/footer.component';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

const FormData = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [donation, setDonation] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [expiry, setExpiry] = React.useState("");
  const [cvv, setCvv] = React.useState("");

  const handleName = (ev) => {
    ev.preventDefault();
    setName(ev.target.value);
  };

  const handleEmail = (ev) => {
    ev.preventDefault();
    setEmail(ev.target.value);
  };

  const handleDonation = (ev) => {
    ev.preventDefault();
    setDonation(ev.target.value);
  };

  const handleCardNumber = (ev) => {
    ev.preventDefault();
    setCardNumber(ev.target.value);
  };

  const handleExpiry = (ev) => {
    ev.preventDefault();
    setExpiry(ev.target.value);
  };

  const handleCvv = (ev) => {
    ev.preventDefault();
    setCvv(ev.target.value);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    return false;
  };


  const form = React.useRef();

  return <form ref={form} className="column32 stretch" onSubmit={handleSubmit}>
    <div className="column16">
      <div className="column8">
        <label className="bold-text">Donation:</label>
        <input
          className="form-control"
          maxLength="256"
          name="Donation"
          placeholder="Enter amount..."
          type="number"
          id="Donation"
          required
          value={donation}
          onChange={handleDonation}
        />
      </div>
      <div className="column8">
        <label className="bold-text">Name:</label>
        <input
          className="form-control"
          maxLength="256"
          name="Name"
          placeholder="Enter name..."
          type="text"
          id="Name"
          required
          value={name}
          onChange={handleName}
        />
      </div>
      <div className="column8">
        <label className="bold-text">Email:</label>
        <input
          className="form-control"
          maxLength="256"
          name="Email"
          placeholder="Enter email..."
          type="email"
          id="Email"
          required
          value={email}
          onChange={handleEmail}
        />
      </div>
      <div className="column8">
        <label className="bold-text">Card Details:</label>
        <input
          className="form-control"
          maxLength="256"
          name="Card-Number"
          placeholder="Enter Card Number...."
          type="number"
          id="Card-Number"
          required
          value={cardNumber}
          onChange={handleCardNumber}
        />
      </div>

      <div className="flex8">
        <div className="col6">
          <div className="column8">
            <label className="bold-text">Expiry:</label>
            <input
              className="form-control"
              maxLength="256"
              name="Expiration-Date"
              placeholder="00/00"
              type="text"
              id="Expiration-Date"
              required
              value={expiry}
              onChange={handleExpiry}
            />
          </div>
        </div>
        <div className="col6">
          <div className="column8">
            <label className="bold-text">CVV:</label>
            <input
              className="form-control"
              maxLength="256"
              name="CVV"
              placeholder="000"
              type="number"
              id="CVV"
              required
              value={cvv}
              onChange={handleCvv} />
          </div>
        </div>
      </div>
    </div>
    <div className='column16'>
      <input
        type="submit"
        className="button-primary w-button"
        value="Donate Now"
      />
    </div>
  </form >
};

const DonatePage = () => {
  const [isSubmitted, SetIsSubmitted] = React.useState(false);
  const [isError, SetIsError] = React.useState(true);

  const DonationForm = () => {

    if (isSubmitted === false && isError === true) {
      return <FormData />;
    }
    else if (isSubmitted === true && isError === true) {
      return <FormData />;
    }

  }

  React.useEffect(() => {
    document.title = "Donate";
  }, []);


  return (<TransitionComponent>
    <HeaderComponent />
    <motion.section className="section"
      initial={{ opacity: 0, transform: `translateY(240px)` }}
      whileInView={{ opacity: 1, transform: `translateY(0px)` }}
      viewport={{ once: true }}
      transition={{ duration: 1 }} >
      <div className="flexAuto top">
        <div className="col6">
          <div className="column32">
            <div className="column16">
              <h1 className="title-large">Donate To Support Our Mission</h1>
              <div className="divider" />
            </div>
            <p className="text">
              Every donation, no matter the size makes a significant impact on
              our mission educating and &nbsp;in our compassionate outreach and
              empowering our children. <br />
              <br />With your support, we can create lasting change and build a
              brighter future for our OVC.
            </p>
          </div>
        </div>
        <div className="col6">
          <div className="card borderbox cardshadow">
            <div className="p32">
              <div className="column32 stretch">
                <DonationForm />

                <AnimatePresence initial={false}>
                  {isSubmitted === true && isError === false && (
                    <motion.div
                      className="success-message"
                      initial={{ transform: `translateY(240px)` }}
                      animate={{ transform: `translateY(0px)` }}
                      transition={{ duration: 0.5, ease: "linear" }}
                    >
                      <p className="text">
                        Thank you! Your submission has been received!
                      </p>
                    </motion.div>
                  )}

                  {isSubmitted === true && isError === true && (
                    <motion.div
                      className="error-message"
                      initial={{ transform: `translateY(240px)` }}
                      animate={{ transform: `translateY(0px)` }}
                      transition={{ duration: 0.5, ease: "linear" }}
                    >
                      <p className="text">
                        Oops! Something went wrong while submitting the
                        form.
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div >
    </motion.section>
    <div className="image-container moveup">
      <ImageComponent
        src="./Assets/Images/Orphans/2.jpg"
        loading="lazy"
        alt=""
        className="image-fluid"
      />
    </div>
    <FooterComponent />
  </TransitionComponent>);

}


export default DonatePage;