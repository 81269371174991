import React, { Component } from 'react';
import { FooterComponent } from '../Components/footer.component';
import HeaderComponent from '../Components/header.component';
import { motion } from 'framer-motion';
import ValuesComponent from '../Components/values.component';
import ImageComponent from '../Components/image.component';
import TransitionComponent from '../Components/transitions.component';

export default class AboutPage extends Component {
  componentDidMount() {
    document.title = "Kadosh-Voice of Glory: Restoring Hope for Orphans & Vulnerable Children";
  }
  render() {
    return (
    <TransitionComponent>
      <HeaderComponent />
      {/* Mission Section */}
      <motion.section className='section'
        initial={{ opacity: 0, transform: `translateY(240px)` }}
        whileInView={{ opacity: 1, transform: `translateY(0px)` }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <div className='flex64'>
          <div className="col6">
            <ImageComponent src="./Assets/Images/mission.png" alt="" className="image-fluid contain" sizes="(max-width: 479px) 90vw, (max-width: 991px) 83vw, 45vw" />
          </div>
          <div className="col6">

            <div className="column32">
              <div className="column16">
                <h2 className="sub-title">What We Stand For</h2>
                <h1 className="title-large">Our Mission</h1>
                <div className="divider" />
              </div>
              <p className="text">Kadosh-Voice of Glory is a U.S faith based international organization dedicated to restoring hope and dignity for Orphans and Vulnerable Children (OVC) through collaborative efforts to provide compassionate outreach, safe havens, nurturing care, and facilitate their healing, integration and reintegration in communities.</p>
            </div>
          </div>
        </div>
      </motion.section>
      {/* Values Section */}
      <ValuesComponent />
      {/* Vision Section */}
      <motion.section className='section'
        initial={{ opacity: 0, transform: `translateY(240px)` }}
        whileInView={{ opacity: 1, transform: `translateY(0px)` }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
      >
        <div className='flex64'>
          <div className="col6">

            <div className="column32">
              <div className="column16">
                <h2 className="sub-title">What Are Our Goals</h2>
                <h1 className="title-large">Our Vision</h1>
                <div className="divider" />
              </div>
              <p className="text">A society where orphan and vulnerable children find hope, dignity, and belonging in supportive families and communities.</p>
            </div>
          </div>
          <div className="col6">
            <ImageComponent src="./Assets/Images/vision.png" alt="" className="image-fluid contain" sizes="(max-width: 479px) 90vw, (max-width: 991px) 83vw, 45vw" />
          </div>
        </div>
      </motion.section>
      <motion.section className='slider'
        initial={{ opacity: 0, transform: `translateY(240px)` }}
        whileInView={{ opacity: 1, transform: `translateY(0px)` }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}>
        <motion.div
          className="slider-items"
          initial={{ transform: 'translate3d(0px, 0px, 0px)' }}
          animate={{ transform: 'translate3d(calc(-100% - 940px), 0px, 0px)' }}
          transition={{ duration: 30, ease: 'linear', repeat: Infinity }}
        >
          <ImageComponent
            alt=""
            src="./Assets/Images/Orphans/Slider 1.jpg"
            loading="lazy"
            className="slider-image"
          /><ImageComponent
            src='./Assets/Images/Orphans/Slider 2.jpg'
            alt=""
            loading="lazy"
            className="slider-image"
          /><ImageComponent
            src='./Assets/Images/Orphans/Slider 3.jpg'
            loading="lazy"
            alt=""
            className="slider-image"
          /><ImageComponent
            src='./Assets/Images/Orphans/Slider 3.jpg'
            loading="lazy"
            alt=""
            className="slider-image"
          /><ImageComponent
            src='./Assets/Images/Orphans/Slider 4.jpg'
            loading="lazy"
            alt=""
            className="slider-image"
          />
        </motion.div>
      </motion.section>
      {/* Footer */}
      <FooterComponent />
    </TransitionComponent>
    )
  }
}
