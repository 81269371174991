import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const AboutDropdown = () => {

    let location = useLocation();
    let current = location.pathname.trim().replace('/', '');
    const [isOpen, setIsOpen] = useState(false);

    // By using `AnimatePresence` to mount and unmount the contents, we can animate
    // them in and out while also only rendering the contents of open accordions
    const toggleDropdown = (ev) => {
        ev.preventDefault();
        setIsOpen(!isOpen);
    }

    return (
        <div className='nav-dropdown'>
            <a className="nav-link" href="/about" onClick={toggleDropdown}>About<FeatherIcon icon="chevron-down" size={22} /> </a>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                        className='nav-menu'
                    >
                        <Link to={`/about`} className={current === 'about' ? 'nav-item active' : 'nav-item'}>About</Link>
                        <Link to={`/partners`} className={current === 'partners' ? 'nav-item active' : 'nav-item'}>Partners</Link>
                        <Link to={`/team`} className={current === 'team' ? 'nav-item active' : 'nav-item'}>Team</Link>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
const HeaderComponent = () => {

    let location = useLocation();
    let current = location.pathname.trim().replace('/', '');
    let Logo = "./Assets/Images/Logo.png";
    const isMobile = useMediaQuery('(max-width:1024px)');

    const [expanded, setExpanded] = React.useState(false);

    return (
        <nav className='navbar'>
            <Link to={`/`}><img src={Logo} alt="" className="navbar-brand" /></Link>

            {isMobile ? <>
                <AnimatePresence initial={false}>
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate={expanded === true ? "open" : "collapsed"}
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        className='menu'
                    >
                        <Link to={`/home`} className={current === 'home' | '' ? 'nav-link active' : 'nav-link'}>Home</Link>
                        <AboutDropdown />
                        <Link to={`/programs`} className={current === 'programs' ? 'nav-link active' : 'nav-link'}>Programs</Link>
                        <Link to={`/current-work`} className={current === 'current-work' ? 'nav-link active' : 'nav-link'}>Current Work</Link>
                        <Link to={`/contact`} className={current === 'contact' ? 'nav-link active' : 'nav-link'}>Contact</Link>
                        <Link to={`/donate`} className='button-primary'>Donate Now <FeatherIcon icon="arrow-up-right" size={22} /></Link>
                    </motion.div>

                </AnimatePresence>

                <motion.button
                    className='menu-button'
                    initial={false}
                    onClick={() => setExpanded(expanded ? false : true)}>
                    <FeatherIcon icon='menu' size={22} />
                </motion.button>
            </>
                :
                <div
                    key="content"
                    initial="collapsed"
                    animate={expanded === true ? "open" : "collapsed"}
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    className='menu'
                >
                    <Link to={`/home`} className={current === 'home' | '' ? 'nav-link active' : 'nav-link'}>Home</Link>
                    <AboutDropdown />
                    <Link to={`/programs`} className={current === 'programs' ? 'nav-link active' : 'nav-link'}>Programs</Link>
                    <Link to={`/current-work`} className={current === 'current-work' ? 'nav-link active' : 'nav-link'}>Current Work</Link>
                    <Link to={`/contact`} className={current === 'contact' ? 'nav-link active' : 'nav-link'}>Contact</Link>
                    <Link to={`/donate`} className='button-primary'>Donate Now <FeatherIcon icon="arrow-up-right" size={22} /></Link>
                </div>
            }
        </nav >
    )

}

export default HeaderComponent;