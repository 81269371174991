import React from 'react';
import HeaderComponent from '../Components/header.component';
import { FooterComponent } from '../Components/footer.component';
import { motion } from "framer-motion";
import TransitionComponent from '../Components/transitions.component';

const TeamPage = () => {

  React.useEffect(() => {
    document.title = "Team";

  }, []);
  const team = [
    {
      name: "Elizabeth Mago",
      role: null,
      description: "President & Treasurer",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/668950ab90f89437c71dc26b_1000008619%201-p-1080.png",
    },
    {
      name: "Dr Isaac Chinyoka",
      role: "Vice President",
      description: "Leads the Committee on Strategic Development & OVC Programming",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/668e80346681a7f2a75c7c9e_IMG-20240709-WA0008-p-500.jpg",
    },
    {
      name: "Dr Ronald Mutasa",
      role: null,
      description: "Leads the Committee on Strategic Fund Raising & Donor Networking",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/66846487187d29ed25e67bb1_1570354257542.jpg",
    },
    {
      name: "Ms Caroline Mubaira",
      role: "Secretary",
      description: "Leads the Committee on Strategic Community Engagement & Participation",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/668aac4e8f684e366272f8bb_IMG-20240707-WA0006.jpg",
    },
    {
      name: "Mr Tapiwa Manjengwa",
      role: null,
      description: "Finance & Auditing",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/66846489ea822d59fd7b2279_1640618472791-p-500.jpg",
    },
    {
      name: "Dr Sandra Jones",
      role: "",
      description: "Advisor: Community reconciliation, integration and re-integration",
      path: null,
    },
    {
      name: "Dr Blessed Ovie",
      role: null,
      description: "Leads the Committee on faith-based initiatives: Liason and Networking",
      path: "https://cdn.prod.website-files.com/66844b71b58456befd3cb336/66b427afaaeea94d35cfc71e_1696259657533-p-500.jpg",
    },
  ]
  return (
    <TransitionComponent>
      <HeaderComponent />
      <motion.section className="section">
        <div className='column32 stretch'>
          <div className='column16'>
            <h2 className="sub-title">Team</h2>
            <h1 className='title-large'>Meet our Board<br />Members</h1>
            <div className='divider'></div>
          </div>
          <div className="grid16">
            {team.map((val) => <div className='col12'>
              <div className='card borderless'>
                <div className="column32">
                  <div className='card-image borderless curved'>
                    {val.path !== null && <img alt={val.name} src={val.path} className='card-img top' />}
                  </div>
                  <div className='column16'>
                    <p className='bold-text'>{val.name}</p>
                    {val.role !== null && <p className='bold-text'>{val.role}</p>}
                    {val.description !== null && <p className='text'>{val.description}</p>}
                  </div>
                </div>
              </div>
            </div>)
            }
          </div>
        </div>
      </motion.section>
      <FooterComponent />
    </TransitionComponent>
  )
}
export default TeamPage;