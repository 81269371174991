import React from 'react';

const ImageComponent = (props) => {
    return (
        <img {...props} />
        // (loading === true && <div className="load-wraper"><div className="activity" /></div>)
    );
}


export default ImageComponent;